.flex-mobile-column {
    flex-direction: column;

    &.coder-columns {
      margin: $padding-32 0;
    }

    .coder-columns__content {
      min-width: auto;
      margin: 0;
    }
  }
// {{< imgcolumns >}}
.coder-img-columns {
    display: flex;

    .coder-img-columns-text-elt {
        flex: 50%;
        padding-right: 20px;
        width: 100%;
    }

    .coder-img-columns-img-elt {
        flex: 50%;
        width: 100%;
    }
}
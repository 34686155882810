*,
*:after,
*:before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  color: $fg-color;
  background-color: $bg-color;
  font-family: $font-family;
  font-size: 1.8em;
  font-weight: 400;
  line-height: 1.8em;

  @media only screen and (max-width: 768px) {
    font-size: 1.6em;
    line-height: 1.6em;
  }
}

iframe[src*=disqus] {
  color-scheme: light;
}

a {
  font-weight: 500;
  color: $link-color;
  text-decoration: none;
  transition: all 0.25s ease-in;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

p {
  margin: 2rem 0 2rem 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family;
  font-weight: 600;
  color: $alt-fg-color;
  margin: 4rem 0 2.5rem 0;

  &:hover .heading-link {
    visibility: visible;
  }

  .heading-link {
    color: $link-color;
    font-weight: inherit;
    text-decoration: none;
    font-size: 80%;
    visibility: hidden;
  }

  .title-link {
    color: inherit;
    font-weight: inherit;
    text-decoration: none;
  }
}

h1 {
  font-size: 3.2rem;
  line-height: 3.6rem;

  @media only screen and (max-width: 768px) {
    font-size: 3rem;
    line-height: 3.4rem;
  }
}

h2 {
  font-size: 2.8rem;
  line-height: 3.2rem;

  @media only screen and (max-width: 768px) {
    font-size: 2.6rem;
    line-height: 3rem;
  }
}

h3 {
  font-size: 2.4rem;
  line-height: 2.8rem;

  @media only screen and (max-width: 768px) {
    font-size: 2.2rem;
    line-height: 2.6rem;
  }
}

h4 {
  font-size: 2.2rem;
  line-height: 2.6rem;

  @media only screen and (max-width: 768px) {
    font-size: 2rem;
    line-height: 2.4rem;
  }
}

h5 {
  font-size: 2rem;
  line-height: 2.4rem;

  @media only screen and (max-width: 768px) {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}

h6 {
  font-size: 1.8rem;
  line-height: 2.2rem;

  @media only screen and (max-width: 768px) {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

b,
strong {
  font-weight: 700;
}

.highlight>div,
.highlight>pre {
  margin: 2rem 0 2rem;
  padding: 1rem;
  border-radius: 1rem;
}

pre {
  display: block;
  font-family: $code-font-family;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.6rem;
  overflow-x: auto;
  margin: 0;

  code {
    display: inline-block;
    background-color: inherit;
    color: inherit;
  }
}

code {
  font-family: $code-font-family;
  font-size: 1.6rem;
  font-weight: 400;
  background-color: $alt-bg-color;
  color: $fg-color;
  border-radius: 0.6rem;
  padding: 0.3rem 0.6rem;
}

blockquote {
  border-left: 2px solid $alt-bg-color;
  padding-left: 2rem;
  line-height: 2.2rem;
  font-weight: 400;
  font-style: italic;
}

th,
td {
  padding: 1.6rem;
}

table {
  border-collapse: collapse;
}

table td,
table th {
  border: 2px solid $alt-fg-color;
}

table tr:first-child th {
  border-top: 0;
}

table tr:last-child td {
  border-bottom: 0;
}

table tr td:first-child,
table tr th:first-child {
  border-left: 0;
}

table tr td:last-child,
table tr th:last-child {
  border-right: 0;
}

img {
  max-width: 100%;
}

figure {
  text-align: center;
}

.preload-transitions * {
  $null-transition: none !important;

  -webkit-transition: $null-transition;
  -moz-transition: $null-transition;
  -ms-transition: $null-transition;
  -o-transition: $null-transition;
  transition: $null-transition;
}

.wrapper {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
  width: 100%;
}

.container {
  margin: 1rem auto;
  max-width: 90rem;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
}

.fab {
  font-weight: 400;
}

.fas {
  font-weight: 700;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.fab {
  font-weight: 400;
}

.fas {
  font-weight: 900;
}
